// Libs
import React from 'react';
import { graphql, Link } from 'gatsby';
import styled, { css } from 'styled-components';
import GraphImg from 'graphcms-image';
import ReactMarkdown from 'react-markdown';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import BreadCrumb from 'components/breadCrumb';
import ListPosts from 'components/blog/listPosts';
import PublicationHighlight from 'components/Publications/Highlight';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;

  @media ${device.laptop} {
    padding: 0 1.25rem;
  }

  @media ${device.mobile} {
    ${props =>
      props.paddingMobile &&
      css`
        padding: ${props => props.paddingMobile};
      `};
  }
`;

const ContentFeaturedMarketing = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const ContentFeaturedMarketingInformation = styled(ReactMarkdown)`
  width: 100%;
  min-width: 25rem;
  max-width: 25rem;

  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}

  h1 {
    font-size: 3.375rem;
    line-height: 3.75rem;
    color: #231f20;
    font-weight: 700;
    margin: 0;
  }

  @media ${device.laptop} {
    min-width: unset;
    max-width: 13rem;

    ${props =>
      props.marginTopLaptop &&
      css`
        margin-top: ${props.marginTopLaptop};
      `}

    h1 {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
  }

  @media ${device.mobile} {
    padding: 0 1.25rem;
    ${props =>
      props.marginTopMobile &&
      css`
        margin-top: ${props.marginTopMobile};
      `}

    h1 {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
  }
`;

const ContentFeaturedMarketingImage = styled.div`
  width: 100%;
  max-width: 35.625rem;

  @media ${device.laptop} {
    max-width: 20rem;
  }

  @media ${device.mobile} {
    max-width: unset;
  }
`;

const HighlightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${props => (props.bgColor ? props.bgColor : '#f4f5f7')};
  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}

  @media ${device.laptop} {
    ${props =>
      props.marginTopLaptop &&
      css`
        margin-top: ${props.marginTopLaptop};
      `}

    ${props =>
      props.marginBottomLaptop &&
      css`
        margin-bottom: ${props.marginBottomLaptop};
      `}
  }

  @media ${device.mobile} {
    ${props =>
      props.marginTopMobile &&
      css`
        margin-top: ${props.marginTopMobile};
      `}

    ${props =>
      props.marginBottomMobile &&
      css`
        margin-bottom: ${props.marginBottomMobile};
      `}
  }
`;

const HighlightPart = styled.div`
  width: 100%;
  max-width: 1170px;
  padding: ${props => (props.padding ? props.padding : '4.375rem 0 3.125rem')};

  @media ${device.laptop} {
    padding: ${props =>
      props.paddingLaptop ? props.paddingLaptop : '3.125rem 1.25rem 1.875rem'};
  }

  @media ${device.mobile} {
    padding: ${props =>
      props.paddingMobile ? props.paddingMobile : '1.875rem 1.25rem'};
  }
`;

const Part = styled.div`
  padding: 4.375rem 0;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
  ${props =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid #c6c8cc;
    `}

  @media ${device.laptop} {
    padding: 1.875rem 0;
  }
`;

const PartTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: normal;
  color: #231f20;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    font-size: 1.125rem;
    margin-bottom: 1.875rem;
  }
`;

const TagCloud = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1.25rem;

  @media ${device.laptop} {
    justify-content: center;
    gap: 0.625rem;
  }

  @media ${device.mobile} {
    justify-content: flex-start;
  }
`;

const TagCloudLink = styled(Link)`
  font-size: 1rem;
  color: #ffffff;
  padding: 0.875rem 1.25rem;
  background: #45a7df;
  border-radius: 6.25rem;

  @media ${device.laptop} {
    font-size: 0.875rem;
    padding: 0.875rem 1.25rem;
  }
`;

function blog({
  data: {
    gcms: { site, categories },
  },
}) {
  const dataSeo = site?.pages ?? [];
  const breadCrumbData = site?.pages?.[0].fragments?.find(
    fragment => fragment.id === 'cklmodzdchemt0b78qnehf89m'
  );
  const featuredMarketingData = site?.pages?.[0].fragments?.find(
    fragment => fragment.id === 'cklmolujshfqd0b78reqmip7g'
  );

  const categoryHighlight = categories.find(category => category.highlight);
  const activeCategories = categories.filter(
    category =>
      category.active && category.tag !== categoryHighlight.tag && category
  );
  const tagCloudCategories = categories.filter(
    category =>
      !category.active &&
      category.tag !== categoryHighlight.tag &&
      category.categories.length !== 0 &&
      category
  );

  return (
    <Layout>
      <SEO dataSeo={dataSeo} />
      <Container paddingMobile="0">
        <BreadCrumb
          marginTop
          marginBreadCrumb="unset"
          markdown={breadCrumbData?.markdown || ''}
        />
        <ContentFeaturedMarketing borderBottom borderBottomMobile={false}>
          <ContentFeaturedMarketingInformation
            marginTop="-7.75rem"
            marginTopLaptop="-2rem"
            marginTopMobile="0"
          >
            {featuredMarketingData?.markdown}
          </ContentFeaturedMarketingInformation>
          <ContentFeaturedMarketingImage>
            {featuredMarketingData?.assetpicker && (
              <GraphImg
                image={featuredMarketingData?.assetpicker}
                alt="Destaque Marketing"
                withWebp={true}
              />
            )}
          </ContentFeaturedMarketingImage>
        </ContentFeaturedMarketing>
      </Container>
      {categoryHighlight && (
        <HighlightContainer
          marginTop="-7.75rem"
          marginTopLaptop="-4rem"
          marginTopMobile="1.875rem"
        >
          <HighlightPart>
            <ListPosts
              title={categoryHighlight?.title ?? ''}
              headerLinkTitle="Ver tudo"
              headerLink={`/blog/${categoryHighlight?.tag}`}
              headerLinkZIndex="2"
              postsToShow={3}
              posts={categoryHighlight.categories}
            />
          </HighlightPart>
        </HighlightContainer>
      )}
      <Container>
        {activeCategories.map(category => (
          <Part key={category.id} borderBottom>
            <ListPosts
              title={category?.title ?? ''}
              headerLinkTitle="Ver tudo"
              headerLink={`/blog/${category?.tag}`}
              postsToShow={3}
              posts={category.categories}
            />
          </Part>
        ))}
        <Part>
          <PartTitle>Mais posts em</PartTitle>
          <TagCloud>
            {tagCloudCategories.map(category => (
              <TagCloudLink key={category.id} to={`/blog/${category.tag}`}>
                {category.title}
              </TagCloudLink>
            ))}
          </TagCloud>
        </Part>
      </Container>
      <HighlightContainer
        bgColor="linear-gradient(135deg, #1D52A3 0%, #45A7DF 100%)"
        marginBottom="4.375rem"
        marginBottomLaptop="1.875rem"
      >
        <HighlightPart padding="4.375rem 0" paddingLaptop="1.875rem">
          <PublicationHighlight />
        </HighlightPart>
      </HighlightContainer>
    </Layout>
  );
}

export const blogPageQuery = graphql`
  query BlogPageQuery {
    gcms {
      site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
        pages(where: { id: "cklmo803chhjx0b688o3wdzxm" }) {
          fragments(locales: [pt_BR, en]) {
            id
            name
            singletexts
            markdown
            assetpicker {
              handle
              width
              height
            }
          }
          seo {
            metaTitle
            metaDescription
            noIndex
            localizations {
              metaTitle
              metaDescription
              noIndex
            }
          }
        }
      }
      categories(locales: [pt_BR, en], orderBy: title_ASC) {
        id
        title
        tag
        highlight
        active
        categories {
          ... on GraphCMS_Post {
            id
            slugPost
            title
            topic
            date
            createdAt
            author
            markdown
            assetpicker {
              handle
              width
              height
            }
            doctors(first: 1000, locales: [pt_BR, en]) {
              id
              id_api
              active
              assignment
              name
            }
          }
        }
      }
    }
  }
`;

export default blog;
